.headerlink-title {
    display: grid;
    justify-content: left;
    color: #6284a1;
    text-decoration: none;
    text-align: center;
  }
  
  .headerlink-dot {
    margin-top: -0.1rem;
    opacity: 0;
    transition: opacity 200ms linear;
  }
  
  .headerlink-title:hover .headerlink-dot {
    opacity: 1;
  }
  
  .headerlink-dot-active {
    margin-top: -0.1rem;
    display: block;
  }
  
  .headerlink-no-link {
    pointer-events: none;
  }

  @media(max-width:768px){
      .headerlink-title{
          justify-content: center;
      }
  }