.home{
    min-width:100%;
    min-height: 100%;
    display:grid;
    grid-template-columns: 1fr 1fr;
}

.home .title{
    width: 100%;
    font-size: 18px;
    text-align: left;
}

.home .title .p{
    line-height: 50px;
}

.home .person img{
    width: 100%;
    justify-items: center;
}

@media (max-width: 768px) {
    .home {
        grid-template-columns: 1fr;
        grid-template-rows: 2fr;
    }
    .home .title{
        width: 100%;
        font-size: 18px;
        text-align: center;
    }
  }