.header {
    display:grid;
    font-size: 25px;
    min-width: 100%;
    grid-template-columns: 10% 10% 10% 70%;
    justify-content: start;
  }

@media(max-width:768px){
    .header{
        grid-template-columns: 1fr 1fr 1fr
    }
}